import { AUTHENTICATION_SYSTEM } from "../../../constants"
import { AccountLoginForm } from "./AccountLoginForm"
import { CurrentForm } from "../../../types"
import {
  RouterQueryParams,
  SwitchToAccountLoginFormParams,
} from "../../../types/Login/user-lookup-form"
import { UserLookupForm } from "./UserLookupForm"
import { extractCompoundSubdomain } from "../../../utils"
import { getStorage, removeStorage } from "../../../utils/storage"
import {
  googleSignInNotEnabledErrorToastNotification,
  signInErrorToastNotification,
} from "../../Notification/ToastNotification"
import { useCsrfTokenContext } from "../../../providers"
import { useRouter } from "next/router"
import { useTrackOnView } from "@cultureamp/amplitude"
import Avo from "../../../Avo"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

const handleGoToUserLookup =
  (setCurrentForm: Dispatch<SetStateAction<CurrentForm>>) => () => {
    setCurrentForm("UserLookup")
    removeStorage()
  }

export const Login: React.FC = () => {
  const router = useRouter()
  const { csrfToken, isLoading: isCsrfLoading } = useCsrfTokenContext()

  const [currentForm, setCurrentForm] = useState<CurrentForm>("UserLookup")
  const [workEmail, setWorkEmail] = useState<string>("")
  const [applicationId, setApplicationId] = useState<string>("")
  const [subdomain, setSubdomain] = useState<string>("")
  const [pageSubdomain, setPageSubdomain] = useState<string>("")
  const [availableLoginOptions, setAvailableLoginOptions] = useState<string[]>(
    [],
  )

  const [showUnrecognisedLoginError, setShowUnrecognisedLoginError] =
    useState<boolean>(false)
  const [unrecognisedLoginErrorCounter, setUnrecognisedLoginErrorCounter] =
    useState<number>(0)

  const handleGoToAccountLogin = (params: SwitchToAccountLoginFormParams) => {
    const {
      applicationId,
      availableLoginOptions,
      workEmail,
      subdomain,
      showUnrecognisedLoginError,
      unrecognisedLoginErrorCounter,
    } = params

    setApplicationId(applicationId)
    setAvailableLoginOptions(availableLoginOptions)
    setWorkEmail(workEmail)
    setSubdomain(subdomain)
    setShowUnrecognisedLoginError(showUnrecognisedLoginError)
    setUnrecognisedLoginErrorCounter(unrecognisedLoginErrorCounter)
    setCurrentForm("AccountLogin")
  }

  useEffect(() => {
    setPageSubdomain(extractCompoundSubdomain(window.location.hostname) ?? "")

    const persistedData = getStorage()
    if (persistedData) {
      const { applicationId, availableLoginOptions, workEmail, subdomain } =
        persistedData

      handleGoToAccountLogin({
        applicationId,
        availableLoginOptions,
        workEmail,
        subdomain,
        showUnrecognisedLoginError: false,
        unrecognisedLoginErrorCounter: 0,
      })
    }
  }, [])

  useEffect(() => {
    let {
      applicationId = "",
      availableLoginOptions = "",
      email = "",
      error = "",
      subdomain = "",
    } = router.query as RouterQueryParams

    if ("redirect" in router.query) {
      const url = new URL(
        router.query["redirect"] as string,
        window.location.origin,
      )
      const params = new URLSearchParams(url.search)

      error = params.get("error") || error
      applicationId = params.get("applicationId") || applicationId
      availableLoginOptions =
        params.get("availableLoginOptions") || availableLoginOptions
      email = params.get("email") || email
      subdomain = params.get("subdomain") || subdomain
    }

    if (router.query["email"]) {
      const [, queryString = ""] = router.asPath.split("?")

      const queryObj = queryString
        .split("&")
        .reduce<Map<string, string>>((prev, curr) => {
          const [key, value] = curr.split("=") as [string, string]
          prev.set(key, value)
          return prev
        }, new Map())

      setWorkEmail(queryObj.get("email") as string)
    }

    const availableLoginOptionsArray = availableLoginOptions
      ? availableLoginOptions.split(",")
      : []

    switch (error) {
      case "UnrecognisedLoginError":
        handleGoToAccountLogin({
          applicationId,
          availableLoginOptions: availableLoginOptionsArray,
          workEmail: email,
          subdomain,
          showUnrecognisedLoginError: true,
          unrecognisedLoginErrorCounter: 1,
        })
        router.replace("/", undefined, { shallow: true })
        break

      case "GoogleSignInNotEnabledError":
        handleGoToAccountLogin({
          applicationId,
          availableLoginOptions: availableLoginOptionsArray,
          workEmail: email,
          subdomain,
          showUnrecognisedLoginError: false,
          unrecognisedLoginErrorCounter: 0,
        })
        googleSignInNotEnabledErrorToastNotification()
        router.replace("/", undefined, { shallow: true })
        break

      case "GoogleOAuthError":
        signInErrorToastNotification()
        router.replace("/", undefined, { shallow: true })
        break

      default:
        break
    }
  }, [router])

  useTrackOnView(
    () =>
      Avo.signInPageViewed({
        errorPresent: null,
        pageSubdomain,
        authenticationSystem: AUTHENTICATION_SYSTEM,
      }),
    !!csrfToken,
  )

  if (isCsrfLoading) {
    return null
  }

  return (
    <>
      {currentForm === "UserLookup" ? (
        <UserLookupForm
          workEmail={workEmail}
          setWorkEmail={setWorkEmail}
          handleGoForward={handleGoToAccountLogin}
        />
      ) : (
        <AccountLoginForm
          workEmail={workEmail}
          handleGoBack={handleGoToUserLookup(setCurrentForm)}
          availableLoginOptions={availableLoginOptions}
          applicationId={applicationId}
          subdomain={subdomain}
          displayUnrecognisedLoginError={showUnrecognisedLoginError}
          unrecognisedLoginErrorCount={unrecognisedLoginErrorCounter}
        />
      )}
    </>
  )
}

import { AriaLiveErrorMessage } from "../../AriaLive/AriaLiveErrorMessage"
import { Button, Card, TextField, VisuallyHidden } from "@kaizen/components"
import { GoogleSignIn } from "../../Button/GoogleSignIn"
import { Icon } from "@kaizen/components/future"
import {
  SubmitParams,
  SwitchToAccountLoginFormParams,
} from "../../../types/Login/user-lookup-form"
import {
  getEnvironmentName,
  getMurmurResetPasswordUrl,
  handleOnBlur,
  handleWorkEmailChange,
  isEmailValid,
} from "../../../utils"
import { setStorage } from "../../../utils/storage"
import { signInErrorToastNotification } from "../../Notification/ToastNotification"
import { useCsrfTokenContext } from "../../../providers"
import { useFlags } from "@cultureamp/next-services"
import { useRouter } from "next/router"
import Link from "next/link"
import React, { FC, useEffect, useRef, useState } from "react"
import styles from "../Form.module.scss"

interface Props {
  workEmail: string
  setWorkEmail: React.Dispatch<React.SetStateAction<string>>
  handleGoForward: (params: SwitchToAccountLoginFormParams) => void
}

// @TODO: The following code for the old application lookup endpoint `/app/auth/api/application-lookup`
// and feature flag `authSubdomainAwareMultiAccountLoginRollout` will be deprecated.
// Please clean up as part of the ticket https://cultureamp.atlassian.net/browse/PAL-824
// ↓↓↓
const handleSubmit =
  (
    submitParams: SubmitParams,
    authSubdomainAwareMultiAccountLoginRollout: boolean,
  ) =>
  () => {
    const {
      workEmail,
      setShowInvalidEmailFormatError,
      textFieldRef,
      setErrorCounter,
      csrfToken,
      setIsSubmitting,
      handleGoForward,
      fakeSubdomain,
    } = submitParams

    const valid = isEmailValid(workEmail)
    setIsSubmitting(true)
    setShowInvalidEmailFormatError(!valid)
    textFieldRef.current?.focus()

    if (valid) {
      let apiEndpoint = authSubdomainAwareMultiAccountLoginRollout
        ? `/app/auth/api/v2/application-lookup`
        : `/app/auth/api/application-lookup`

      if (fakeSubdomain) {
        apiEndpoint = `${apiEndpoint}?fake-subdomain=${fakeSubdomain}`
      }

      const requestBody = {
        email: workEmail,
        csrfToken,
        ...(authSubdomainAwareMultiAccountLoginRollout && { isV2Api: true }),
      }

      fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error("Unable to lookup user")
          }
        })
        .then(({ data }) => {
          if (
            data.redirectUrl &&
            !data.redirectUrl.includes(window.location.host)
          ) {
            window.location.href = data.redirectUrl
            return
          }
          handleGoForward({
            applicationId: data.applicationId,
            availableLoginOptions: data.availableLoginOptions,
            workEmail,
            subdomain: data.subdomain,
            showUnrecognisedLoginError: false,
            unrecognisedLoginErrorCounter: 0,
          })

          setStorage(
            data.applicationId,
            data.availableLoginOptions,
            Date.now(),
            getEnvironmentName(),
            data.subdomain,
            workEmail,
          )
        })
        .catch(() => {
          signInErrorToastNotification()
          setIsSubmitting(false)
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    } else {
      setErrorCounter(prevCount => prevCount + 1)
      setIsSubmitting(false)
    }
  }

// @TODO: The feature flag `authSubdomainAwareMultiAccountLoginRollout` will be deprecated.
// Please remove it as part of the ticket https://cultureamp.atlassian.net/browse/PAL-824
// ↓↓↓
export const UserLookupForm: FC<Props> = ({
  workEmail,
  setWorkEmail,
  handleGoForward,
}) => {
  const router = useRouter()
  const { csrfToken, isLoading } = useCsrfTokenContext()
  const { authSubdomainAwareMultiAccountLoginRollout } = useFlags()

  const textFieldRef = useRef<HTMLInputElement | null>(null)
  const [showInvalidEmailFormatError, setShowInvalidEmailFormatError] =
    useState<boolean>(false)
  const [errorCounter, setErrorCounter] = useState<number>(0)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [resetPasswordUrl, setResetPasswordUrl] = useState<string>("")
  const [fakeSubdomain, setFakeSubdomain] = useState<string>()

  useEffect(() => {
    textFieldRef.current?.focus()

    setFakeSubdomain(router.query["fake-subdomain"] as string)

    setResetPasswordUrl(
      getMurmurResetPasswordUrl(
        window.location.protocol,
        window.location.hostname,
      ),
    )
  }, [router.query])

  if (isLoading || !csrfToken) {
    return null
  }

  const submitParams: SubmitParams = {
    workEmail,
    setShowInvalidEmailFormatError,
    textFieldRef,
    setErrorCounter,
    csrfToken,
    setIsSubmitting,
    handleGoForward,
    fakeSubdomain,
  }

  return (
    <Card classNameOverride={styles.form}>
      <div className="p-24">
        <div className="mt-6 mb-24">
          <TextField
            id="email"
            inputRef={textFieldRef}
            type="email"
            labelText="Work email"
            icon={<Icon name="mail" isPresentational isFilled />}
            value={workEmail}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            status={showInvalidEmailFormatError ? "error" : "default"}
            validationMessage={
              showInvalidEmailFormatError
                ? "Please enter a valid email address"
                : ""
            }
            onChange={handleWorkEmailChange(setWorkEmail)}
            onBlur={handleOnBlur(
              workEmail,
              setShowInvalidEmailFormatError,
              setErrorCounter,
            )}
            onKeyDown={event => {
              if (event.key === "Enter") {
                event.preventDefault()
                handleSubmit(
                  submitParams,
                  authSubdomainAwareMultiAccountLoginRollout,
                )()
              }
            }}
          />
        </div>
        <VisuallyHidden>
          {errorCounter > 0 && (
            <AriaLiveErrorMessage
              counter={errorCounter}
              message="Please enter a valid email address"
            />
          )}
        </VisuallyHidden>

        <div className="mb-12">
          <Button
            label="Continue"
            aria-label="Continue with email"
            onClick={handleSubmit(
              submitParams,
              authSubdomainAwareMultiAccountLoginRollout,
            )}
            primary
            fullWidth
            disabled={isSubmitting}
          />
        </div>

        <GoogleSignIn />

        <div className="p-6">
          <Link
            href={{
              pathname: resetPasswordUrl,
            }}
            legacyBehavior={false}
            className={styles.bottomLink}
            aria-label="Can't sign in? Recover your account here."
          >
            Can't sign in?
          </Link>
        </div>
      </div>
    </Card>
  )
}
